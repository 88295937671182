import { writable } from 'svelte/store';

// Export the store so we can use it in any component
export const imagePopout = writable(null);

export async function popoutImage(url, mods = {}) {
	let resolve;

	const promise = new Promise((_resolve) => {
		resolve = _resolve;
	});

	/**
	 *  Possible Mods:
	 *  - TBD
	 */

	imagePopout.set({
		url,
		mods,
		resolve,
	});

	return promise;
}
